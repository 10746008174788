import {
  MouseEventHandler,
  TouchEventHandler,
  useCallback,
  useRef,
  useState,
} from "react";

export interface UsePinWithBalloonResponse {
  mouseOverHandler: MouseEventHandler;
  mouseLeaveHandler: MouseEventHandler;
  touchStartHandler: TouchEventHandler;
  cancelHideBalloonTimer: () => void;
  showBalloon: boolean;
}

const usePinWithBalloon = (): UsePinWithBalloonResponse => {
  const [showBalloon, setShowBalloon] = useState(false);
  const refLeaveTimer = useRef(-1);

  const cancelHideBalloonTimer = useCallback(() => {
    clearTimeout(refLeaveTimer.current);
  }, [refLeaveTimer]);

  // ピンにマウスオーバーしたとき
  const mouseOverHandler = useCallback(() => {
    cancelHideBalloonTimer();
    setShowBalloon(true);
  }, [setShowBalloon, cancelHideBalloonTimer]);

  // ピンから吹き出しからマウスリーブしたとき
  const mouseLeaveHandler = useCallback(async () => {
    cancelHideBalloonTimer();

    refLeaveTimer.current = window.setTimeout(() => {
      setShowBalloon(false);
    }, 500);
  }, [refLeaveTimer, cancelHideBalloonTimer, setShowBalloon]);

  const touchStartHandler = useCallback(() => {
    setShowBalloon(!showBalloon);
  }, [setShowBalloon, showBalloon]);

  return {
    mouseOverHandler,
    mouseLeaveHandler,
    touchStartHandler,
    cancelHideBalloonTimer,
    showBalloon,
  };
};
export default usePinWithBalloon;
