import { UploadImageResponse } from "@/web-client/api";
import { ImageSize } from "@/consts/ImageSize";
import useBackgroundImage from "@/hooks/useBackgroundImage";
import { FC } from "react";

interface Props {
  image: UploadImageResponse;
  imageSize: ImageSize;
  aspectRatio?: string;
  backgroundSize?: "cover" | "contain";
  backgroundColor?: string;
}

const FadingBackgroundImage: FC<Props> = ({
  image,
  imageSize,
  aspectRatio,
  backgroundSize = "cover",
  backgroundColor,
}): JSX.Element => {
  const { imageRef, imageStyle, containerStyle, animateScope } =
    useBackgroundImage(
      image,
      imageSize,
      aspectRatio,
      backgroundSize,
      500,
      backgroundColor,
    );

  return (
    <div ref={imageRef} style={containerStyle}>
      <div
        className="w-full h-full opacity-0"
        style={imageStyle}
        ref={animateScope}
      ></div>
    </div>
  );
};

export default FadingBackgroundImage;
