import { PostPinResponse } from "@/web-client/api";
import { FC, useMemo } from "react";
import usePinWithBalloon from "@/features/pin/hooks/usePinWithBalloon";
import PinWithBalloon from "@/features/pin/components/PinWithBalloon";
import MaterialPin from "@/features/pin/components/MaterialPin";
import useSearchQueryParameter from "@/hooks/useSearchQueryParameter";
import { ArchitectureSearchQueryParameter } from "@/types/ArchitectureSearchQueryParameter";
import dynamic from "next/dynamic";
const DynamicPinBalloon = dynamic(
  () => import("@/features/pin/components/PinBalloon"),
);
const DynamicMaterialBalloonItem = dynamic(
  () => import("./MaterialBalloonItem"),
);

interface Props {
  pin: PostPinResponse;
}

const MaterialPinWithBalloon: FC<Props> = ({ pin }): JSX.Element => {
  const response = usePinWithBalloon();
  const { queryController } =
    useSearchQueryParameter<ArchitectureSearchQueryParameter>("/posts");
  const path = useMemo<string>(
    () =>
      queryController.toPath({
        material_id: pin.material_pin.material_id.toString(),
        color_type_ids: pin.material_pin.color_type_id?.toString(),
        part_ids: pin.material_pin.part?.id.toString(),
      }),
    [pin, queryController],
  );

  return (
    <PinWithBalloon
      response={response}
      pin={pin}
      pinElement={<MaterialPin onTouchStart={response.touchStartHandler} />}
      balloonElement={
        <DynamicPinBalloon
          onMouseEnter={response.mouseOverHandler}
          onMouseLeave={response.mouseLeaveHandler}
        >
          <DynamicMaterialBalloonItem material={pin.material_pin} path={path} />
        </DynamicPinBalloon>
      }
    />
  );
};

export default MaterialPinWithBalloon;
