import { UploadImageResponse } from "@/web-client/api";
import { ImageSize } from "@/consts/ImageSize";
import { AnimationScope, useAnimate } from "framer-motion";
import { useEffect, useMemo, CSSProperties } from "react";
import { useInView } from "react-intersection-observer";
import { theme } from "tailwind.config";
import useImageLoader from "./useImageLoader";

interface UseBackgroundImageReturn {
  imageRef: (node?: Element) => void;
  imageStyle: CSSProperties;
  imageUrl: string;
  containerStyle: CSSProperties;
  offset?: number;
  animateScope: AnimationScope;
  backgroundColor?: string;
}

const useBackgroundImage = (
  uploadImage: UploadImageResponse,
  size?: ImageSize,
  aspectRatio?: string,
  backgroundSize: "cover" | "contain" = "cover",
  offset = 0,
  backgroundColor = theme.colors.gray[200],
): UseBackgroundImageReturn => {
  const [scope, animate] = useAnimate();
  const imageUrl = useMemo(
    () =>
      size == ImageSize.Small
        ? uploadImage?.urls?.small
        : uploadImage?.urls?.original,
    [uploadImage, size],
  );

  const imageStyle = useMemo<CSSProperties>(() => {
    if (!imageUrl) return {};

    return {
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: backgroundSize,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };
  }, [imageUrl, backgroundSize]);

  const containerStyle = useMemo<CSSProperties>(() => {
    if (!imageUrl) return {};

    return {
      aspectRatio: aspectRatio || `${uploadImage.width}/${uploadImage.height}`,
      backgroundColor,
    };
  }, [imageUrl, aspectRatio, uploadImage, backgroundColor]);

  const { ref: imageRef, inView } = useInView({
    rootMargin: `${offset}px`, // 表示される何px前にinViewをtrueにするか
    triggerOnce: true,
  });

  const { isComplete } = useImageLoader(inView ? imageUrl : undefined);

  useEffect(() => {
    if (isComplete) {
      animate(scope.current, { opacity: 1 });
    }
  }, [isComplete, scope, animate]);

  return {
    imageRef,
    imageStyle,
    imageUrl,
    containerStyle,
    animateScope: scope,
  };
};

export default useBackgroundImage;
