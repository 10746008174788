import useDevice from "@/hooks/useDevice";
import { FC, TouchEventHandler } from "react";

interface Props {
  onTouchStart?: TouchEventHandler;
}

const MaterialPin: FC<Props> = ({ onTouchStart }): JSX.Element => {
  const { isSp } = useDevice();

  if (isSp) {
    return <div className="Pin" onTouchStart={onTouchStart} />;
  }

  return <div className="Pin" />;
};

export default MaterialPin;
