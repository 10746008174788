import { PostPinResponse } from "@/web-client/api";
import { FC, useMemo, useState } from "react";
import { UsePinWithBalloonResponse } from "@/features/pin/hooks/usePinWithBalloon";
import {
  autoUpdate,
  flip,
  offset,
  size,
  useFloating,
} from "@floating-ui/react";
import useFloatingPlacementForPin from "@/features/pin/hooks/useFloatingPlacementForPin";
import { flushSync } from "react-dom";

interface Props {
  pin: PostPinResponse;
  pinElement: JSX.Element;
  balloonElement: JSX.Element;
  response: UsePinWithBalloonResponse;
}

const Offset = 16;

const PinWithBalloon: FC<Props> = ({
  pin,
  pinElement,
  balloonElement,
  response,
}): JSX.Element => {
  const {
    showBalloon,
    mouseOverHandler,
    mouseLeaveHandler,
    touchStartHandler,
    cancelHideBalloonTimer,
  } = response;

  const left = useMemo(() => pin.position.x * 100, [pin.position.x]);
  const top = useMemo(() => pin.position.y * 100, [pin.position.y]);
  const [maxWidth, setMaxWidth] = useState(0);

  const { refs, floatingStyles } = useFloating({
    placement: useFloatingPlacementForPin(pin.position.x, pin.position.y),
    middleware: [
      offset(16),
      flip(),
      size({
        apply({ availableWidth }) {
          flushSync(() => {
            // availableWidthが大きくなってしまった時に、バルーンもそれに合わせて大きくなりすぎてしまうので最大幅240として制限
            setMaxWidth(availableWidth);
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  return (
    <>
      <div
        ref={refs.setReference}
        className="absolute pointer-events-auto"
        style={{
          left: `${left}%`,
          top: `${top}%`,
        }}
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        onTouchStart={touchStartHandler}
      >
        {pinElement}
      </div>

      {showBalloon && (
        <div
          ref={refs.setFloating}
          className="absolute z-10 pointer-events-none"
          style={{
            ...floatingStyles,
            maxWidth: maxWidth - Offset,
          }}
          onMouseEnter={cancelHideBalloonTimer}
        >
          {balloonElement}
        </div>
      )}
    </>
  );
};

export default PinWithBalloon;
