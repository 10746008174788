import { Placement } from "@floating-ui/react";
import { useMemo } from "react";

const useFloatingPlacementForPin = (left: number, top: number): Placement => {
  // 画像をはみ出ないように、ピンの位置で表示する水平方向を決定する
  const placementV = useMemo<"start" | "end" | undefined>(() => {
    if (top > 0.25 && top < 0.75) return undefined;

    if (top < 0.5) return "start";

    return "end";
  }, [top]);

  const placementH = useMemo(() => {
    // 真ん中付近のピン - 左右に表示する
    if (left > 0.25 && left < 0.75) {
      if (left < 0.5) {
        return "right";
      } else {
        return "left";
      }
    }

    if (left < 0.25) return "right";

    return "left";
  }, [left]);

  const placement = useMemo<Placement>(() => {
    return placementV ? `${placementH}-${placementV}` : placementH;
  }, [placementV, placementH]);

  return placement;
};
export default useFloatingPlacementForPin;
