import { useRouter } from "next/router";
import { ParsedUrlQuery, stringify } from "querystring";
import { useCallback } from "react";
import { Url } from "url";

export interface SearchQueryParameterResponse<T> {
  query: T;
  queryController: SearchQueryParameterController<T>;
}

type AddQueryFunc<T> = (key: keyof T, value: string) => void;
type DeleteQueryFunc<T> = (key: keyof T) => void;
type DeleteQueryAtFunc<T> = (key: keyof T, value: string) => void;

export interface SearchQueryParameterController<T> {
  toPath(updateQuery?: T, keepCurrentQuery?: boolean): string;
  addQuery: AddQueryFunc<T>;
  deleteQuery: DeleteQueryFunc<T>;
  deleteQueryAt: DeleteQueryAtFunc<T>;
  submitQuery(as?: Url, scroll?: boolean): void;
  replaceQuery(as?: string): void;
  hasMultiValue(key: keyof T): boolean;
  basePath: string;
}

const useSearchQueryParameter = <T extends ParsedUrlQuery>(
  basePath: string,
  defaultQuery: { [key in keyof T]?: string } = {},
): SearchQueryParameterResponse<T> => {
  const router = useRouter();
  const query: T = Object.assign({}, router.query as T);

  // URLのパスに含まれるIDをQueryStringとして扱いたくないため、強制的に削除する
  delete query["id"];
  // modal用のパラメータも、強制的に削除する
  delete query["entityId"];
  delete query["entityType"];
  delete query["v_id"];

  const updateQuery: T = { ...query };

  const toPath = (updateQuery?: T, keepCurrentQuery = true): string => {
    const mergedQuery = keepCurrentQuery
      ? { ...query, ...updateQuery, ...defaultQuery }
      : { ...updateQuery, ...defaultQuery };

    if (updateQuery) {
      Object.keys(updateQuery).map((key) => {
        const v = updateQuery[key];

        if (!v) {
          delete mergedQuery[key];
        }
      });
    }

    return `${basePath}?${stringify(mergedQuery)}`;
  };

  const addQuery = (key: keyof T, value: string | undefined) => {
    if (!value) {
      delete updateQuery[key];

      return;
    }

    updateQuery[key] = value as any;
  };

  const deleteQuery = (key: keyof T) => {
    delete updateQuery[key];
  };

  const deleteQueryAt = (key: keyof T, value: string) => {
    if (hasMultiValue(key)) {
      const existedValue = updateQuery[key] as string;
      const values = existedValue.split(",");
      const filteredValues = values.filter((v) => v !== value);
      updateQuery[key] = filteredValues.join() as any;
    } else {
      deleteQuery(key);
    }
  };

  const submitQuery = (as: Url = undefined, scroll = false) => {
    const queryString = stringify(updateQuery);
    router.push(queryString ? `${basePath}?${queryString}` : basePath, as, {
      scroll,
    });
  };

  const replaceQuery = useCallback(
    (as?: string) => {
      const path = `${basePath}?${stringify(updateQuery)}`;
      router.replace(path, as, { scroll: false });
    },
    [router, basePath, updateQuery],
  );

  const hasMultiValue = (key: keyof T) => {
    const value = updateQuery[key] as string;

    return /,/.test(value);
  };

  return {
    query,
    queryController: {
      toPath,
      addQuery,
      deleteQuery,
      deleteQueryAt,
      submitQuery,
      replaceQuery,
      hasMultiValue,
      basePath,
    },
  };
};

export default useSearchQueryParameter;
