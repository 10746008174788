import { useState, useEffect, useMemo } from "react";

type UseImageLoaderReturn = {
  isError: boolean;
  isLoading: boolean;
  isComplete: boolean;
};

const useImageLoader = (src?: string): UseImageLoaderReturn => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const isComplete = useMemo(
    () => isLoading === false && isError === false,
    [isLoading, isError],
  );

  useEffect(() => {
    if (!src) return;

    setIsLoading(true);
    setIsError(false);

    // 画像読み込み成功時の処理
    const handleLoad = () => {
      setIsLoading(false);
      setIsError(false);
    };

    // 画像読み込みエラー時の処理
    const handleError = () => {
      setIsLoading(false);
      setIsError(true);
    };

    const image = new Image();
    image.addEventListener("load", handleLoad);
    image.addEventListener("error", handleError);
    image.src = src;

    // コンポーネントのアンマウント時にイベントリスナーをクリーンアップ
    return () => {
      image.removeEventListener("load", handleLoad);
      image.removeEventListener("error", handleError);
    };
  }, [src]);

  return {
    isLoading,
    isError,
    isComplete,
  };
};

export default useImageLoader;
