import { ProductVariationWithProductResponse } from "@/web-client/api";
import useDevice from "@/hooks/useDevice";
import { FC } from "react";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";

interface Props {
  productVariation: ProductVariationWithProductResponse;
  onClick?: () => void;
  onTouchStart?: (e) => void;
}

const ProductVariationPin: FC<Props> = ({
  productVariation,
  onClick,
  onTouchStart,
}): JSX.Element => {
  const { isSp } = useDevice();

  if (isSp) {
    return <div className="Pin animation" onTouchStart={onTouchStart} />;
  }

  return (
    <LinkAsModal
      entityId={productVariation.product.id}
      entityType={ModalEntityType.Product}
      as={`/products/${productVariation.product.id}?v_id=${productVariation.id}`}
      params={{ v_id: productVariation.id.toString() }}
    >
      <a className="Pin animation" onClick={onClick}></a>
    </LinkAsModal>
  );
};

export default ProductVariationPin;
