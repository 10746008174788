import { PostPinResponse } from "@/web-client/api";
import usePinWithBalloon from "@/features/pin/hooks/usePinWithBalloon";
import { FC, useMemo } from "react";

import PinWithBalloon from "@/features/pin/components/PinWithBalloon";
import dynamic from "next/dynamic";
import Link from "next/link";
import NotePin from "@/features/pin/components/NotePin";
const DynamicPinBalloon = dynamic(
  () => import("@/features/pin/components/PinBalloon"),
);

interface Props {
  pin: PostPinResponse;
}

const NotePinWithBalloon: FC<Props> = ({ pin }) => {
  const response = usePinWithBalloon();
  const makerName = useMemo<string>(() => {
    return pin.note_pin.maker?.name || pin.note_pin.maker_text;
  }, [pin]);
  const displayLink = useMemo<boolean>(
    () => pin.note_pin.maker && pin.note_pin.maker.is_contracted,
    [pin],
  );

  return (
    <PinWithBalloon
      response={response}
      pin={pin}
      pinElement={
        <NotePin
          onTouchStart={response.touchStartHandler}
          notePin={pin.note_pin}
        />
      }
      balloonElement={
        <DynamicPinBalloon
          onMouseEnter={response.mouseOverHandler}
          onMouseLeave={response.mouseLeaveHandler}
        >
          <div className="flex flex-col overflow-hidden space-y-4">
            <h1 className="font-bold truncate w-full text-base">
              {pin.note_pin.text}
            </h1>
            <ul className="text-xs font-bold">
              {makerName && (
                <li>
                  メーカー:
                  {displayLink ? (
                    <Link
                      href={`/makers/${pin.note_pin.maker.id}`}
                      className="text-link"
                    >
                      {makerName}
                    </Link>
                  ) : (
                    <>{makerName}</>
                  )}
                </li>
              )}
            </ul>
          </div>
        </DynamicPinBalloon>
      }
    />
  );
};

export default NotePinWithBalloon;
