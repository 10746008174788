import Link from "next/link";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { FC, ReactNode, useMemo } from "react";
import { UrlObject } from "url";

export enum ModalEntityType {
  Post = "post",
  Product = "product",
  Project = "project",
}

interface Props {
  entityId: number;
  entityType: ModalEntityType;
  as: string;
  params?: ParsedUrlQuery;
  children: ReactNode;
  className?: string;
}

const LinkAsModal: FC<Props> = ({
  as,
  entityId,
  entityType,
  children,
  params = {},
  className,
}) => {
  const router = useRouter();

  const isOpen = useMemo<boolean>(() => {
    return !!router.query.entityId && !!router.query.entityType;
  }, [router]);

  const href = useMemo<UrlObject>(() => {
    return {
      pathname: router.pathname,
      query: { ...(router.query || {}), ...params, entityId, entityType },
    };
  }, [entityId, entityType, router, params]);

  return (
    <Link
      as={as}
      href={href}
      prefetch={false}
      shallow={true}
      replace={isOpen}
      scroll={false}
      legacyBehavior
      className={className}
    >
      {children}
    </Link>
  );
};

export default LinkAsModal;
