import { NotePinResponse } from "@/web-client/api";
import useDevice from "@/hooks/useDevice";
import Link from "next/link";
import { FC } from "react";

interface Props {
  notePin: NotePinResponse;
  onClick?: () => void;
  onTouchStart?: (e) => void;
}

const NotePin: FC<Props> = ({
  notePin,
  onClick,
  onTouchStart,
}): JSX.Element => {
  const { isSp } = useDevice();

  if (isSp || !notePin.maker) {
    return <div className="Pin animation" onTouchStart={onTouchStart} />;
  }

  return (
    <Link
      href={`/makers/${notePin.maker.id}`}
      prefetch={false}
      className="Pin animation"
      onClick={onClick}
    ></Link>
  );
};

export default NotePin;
